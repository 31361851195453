.AppBar-root {
  position: relative;
}

.AppBar-root.fixed {
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 10;
  background-color: #212B36;
}

@media (min-width: 1200px) {
  .AppBar-root {
    position: relative;
  }

  .AppBar-root.fixed {
    position: fixed;
    top: 87px;
    left: 0;
    z-index: 10;
    background-color: #212B36;
  }
}