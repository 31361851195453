.certificate-wrap {
  position: relative;
  width: 842px;
  height: 620px;
  background-size: cover;
  overflow: hidden;
}

.verification-section {
  position: relative;
  width: 225px;
}

.verification-section > div {
  position: absolute;
  right: 0;
  font-family: 'Prompt';
  font-weight: bold;
  font-size: 10px;
  line-height: 0px;
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'end';
  text-align: right;
  gap: '5px';
}

.qr-img {
  width: 100.74px;
}

.cert-title-section {
  display: grid;
  grid-gap: 6rem;
  grid-template-columns: repeat(12, minmax(0px, 1fr));
  width: 100%;
  align-items: center;
}
.logo {
  width: 74px;
  height: 74px;
  grid-column: span 1 / span 1;
}
.cert-title {
  line-height: 28px;
  grid-column: span 10 / span 10;
}
.cert-coursedetail-section {
  margin-top: 55px;
}
.coursedetail-description1,
.coursedetail-description2,
.coursedetail-name,
.coursedetail-coursename {
  font-family: 'Prompt';
}
.coursedetail-description1 {
  font-weight: normal;
  font-size: 18px;
  line-height: 0px;
}
.coursedetail-name {
  font-weight: 600;
  font-size: 32px;
  line-height: 33px;
  color: #ffbe01;
}
.coursedetail-description2 {
  font-weight: normal;
  font-size: 16px;
  line-height: 0px;
}
.coursedetail-coursename {
  word-break: break-word;
  font-weight: bold;
  font-size: 32px;
  line-height: 30px;
  max-width: 500px;
  color: #ffbe01;
}

.cert-approval-section {
  display: flex;
  justify-content: space-between;
}

.signature {
  width: 205px;
  height: 45px;
}

.cert-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #ffbe01;
  display: grid;
  grid-gap: 83px;
  grid-template-columns: repeat(12, minmax(0px, 1fr));
  align-items: center;
}
.footer-logo {
  grid-column: span 3 / span 3;
}
.footer-tagline {
  grid-column: span 9 / span 9;
}

.bg-bit_bottomLeft,
.bg-bit_topRight {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
}
.bg-bit_topRight {
  top: -10px;
  right: 0;
}
.bg-bit_bottomLeft {
  bottom: -10px;
  left: -20px;
}
.bg-star, .bg-laptop{
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}
.bg-star{
  top: -130px;
  right: -148px;
  width: 325px;
  height: 320px;
}
.bg-laptop{
  top: -50px;
  right: -20px;
  width: 205px;
  height: 220px;
}